import { io } from 'socket.io-client'
import authConfig from './authConfig'
import { getCurrentUser } from './constants'
import { toast } from 'react-toastify'
import store from '../redux/store'
import dayjs from 'dayjs'
import { getAllUsers } from '../redux/users/action'
import { getAllProjectsSettings } from '../redux/projects/action'
import { getUserDetail } from '../redux/login/action'

const URL = process.env.REACT_APP_SOCKET_URL

/**
 * Socket function to create a socket instance with authentication token.
 * @returns {Socket} Socket instance
 */
export const Socket = () => {
  const token = getCurrentUser() // Assuming getCurrentUser() returns an object with a token property
  try {
    const socket = io(URL, {
      extraHeaders: {
        token: `Bearer ${token}`
      }
    })
    return socket
  } catch (error) {
    console.log(error, 'Socket error')
    throw error // Rethrow the error so that the caller can handle it
  }
}

/**
 * Variable to store the socket instance.
 * If the socket is connected, this variable will hold the socket instance.
 * It can be used to interact with the socket throughout the application.
 * @type {any | null} Socket instance or null if not connected
 */

export let socket = null

export const connectSocket = () => {
  if (!socket) {
    // Check if socket is already initialized
    socket = Socket() // Initialize the socket once
    socket.on('connect', () => {
      console.log('IsConnected', socket.connected)
      localStorage.setItem(authConfig.socketToken, socket.id)
    })
    // You can add more event listeners here if needed
  }
}

export const disconnectSoket = () => {
  socket?.disconnect()
  localStorage.removeItem(authConfig.socketToken)
}

export const fetchConversation = (dispatch, conversationId) => {
  socket?.off('eventInfo')

  socket?.on('eventInfo', response => {
    let isUnique = store
      .getState()
      .smsEmail?.ConvMessages?.messages?.some(message => message?.id !== response?.threadId)

    if (response && isUnique) {
      setTimeout(() => {
        dispatch({
          type: 'USER_SEND_MESSAGE',
          payload: ''
        })
      }, 1500)
      if (
        response?.conversationId === conversationId &&
        store.getState().smsEmail?.userSendMessage != response?.threadId &&
        isUnique
      ) {
        toast.success('New message arrived', {
          position: 'bottom-right',
          theme: 'colored'
        })
        try {
          let modifiedResponse = { ...response }

          switch (response.messageType) {
            case 'SMS':
              modifiedResponse.type = 2
              break
            case 'Email':
              modifiedResponse.id = response?.threadId
              modifiedResponse.type = 3
              modifiedResponse.locationId = response?.locationId
              modifiedResponse.contactId = response?.contactId
              modifiedResponse.contentType = 'text/html'
              modifiedResponse.conversationId = response?.conversationId
              modifiedResponse.dateAdded = response?.dateAdded
              modifiedResponse.userId = response?.userId
              modifiedResponse.meta = {
                email: {
                  messageIds: [response?.emailMessageId]
                }
              }
              break
            default:
              break
          }

          dispatch({
            type: 'GET_EMAIL_DETAIL',
            payload: modifiedResponse
          })

          dispatch({
            type: 'GET_SMS_DETAIL',
            payload: modifiedResponse
          })
        } catch (error) {
          console.error('Error processing conversation:', error)
          // Handle error gracefully, maybe dispatch an action to notify the UI or log it
        }
      }
    }
  })
}

export const fetchOpportunityEvents = dispatch => {
  // Clean up previous listeners
  if (socket) {
    socket.off('eventInfo')
  }

  // Event handler function
  const handleEventInfo = response => {
    switch (response?.type) {
      case 'OpportunityDelete':
        dispatch({ type: 'DELETE_OPPORTINITY', payload: response })
        break
      case 'OpportunityStageUpdate':
        dispatch(getAllProjectsSettings({ pipelineId: store?.getState()?.calenders?.savedPipleline?.pipelineId }))
        dispatch(getAllUsers())
        dispatch(getUserDetail())
        dispatch({
          type: 'OPPORTUNITIES_STAGE_UPDATE',
          payload: response,
          userDetail: store?.getState()?.login?.userDetails,
          savedPipleline: store?.getState()?.calenders?.savedPipleline
        })
        break
      case 'OpportunityCreate':
        dispatch(getAllProjectsSettings({ pipelineId: store?.getState()?.calenders?.savedPipleline?.pipelineId }))
        dispatch(getAllUsers())
        dispatch({ type: 'OPPORTUNITIES_CREATE', payload: response })
        break
      default:
        console.warn(`Unhandled event type: ${response?.type}`)
    }
  }

  // Set up the new listener
  if (socket) {
    socket.on('eventInfo', handleEventInfo)
  }
}

export const fetchCalenderUrl = (setOneTimeLink, setIsLink, setOpenLinkModal, setLinkUniqueHash, linkUniqueHash) => {
  socket?.off('calendarInfo')

  socket?.on('calendarInfo', response => {
    if (response && linkUniqueHash) {
      setOneTimeLink(response?.customData?.link)
      setIsLink(false)
      setLinkUniqueHash('')
      setOpenLinkModal(true)
    }
  })
}

export const joinRoom = async (id, type, userId) => {
  try {
    await connectSocket() // Wait for the socket to connect
    socket.emit('joinRoom', { project_id: id, type: type, userId: userId })
  } catch (err) {
    console.error('Failed to join room:', err)
  }
}

export const sendMessage = params => {
  try {
    socket?.emit('sendMessage', params, response => {})
  } catch (err) {
    console.log(err, 'errrr')
  }
}

export const userAddEdit = params => {
  try {
    socket?.emit('userAddEdit', params, response => {})
  } catch (err) {
    console.log(err, 'errrr')
  }
}

export const userAddEditListion = dispatch => {
  // Remove any existing 'userAddEdit' event listener before adding a new one
  socket?.off('userAddEdit')

  const handlePrivateMessages = response => {
    if (response?.isUserAction) {
      dispatch(getUserDetail())
    }
  }

  // Add a new 'userAddEdit' event listener
  socket?.on('userAddEdit', handlePrivateMessages)

  // Return a cleanup function to remove the 'userAddEdit' event listener
  return () => {
    socket?.off('userAddEdit', handlePrivateMessages)
  }
}

export const ListionMessage = (dispatch, id, type, activeTab) => {
  socket?.off('privateMessages')

  const handlePrivateMessages = response => {
    let userInfo = store?.getState()?.login?.userDetails
    if (activeTab !== 'chat') {
      let modifiedResponse = { ...response }
      modifiedResponse.createdAt = dayjs().toISOString()
      modifiedResponse.updatedAt = dayjs().toISOString()
      if (response?.type !== type && response?.project_id === id && userInfo?._id !== response.user_id) {
        dispatch({
          type: 'INCREASE_COUNT',
          payload: response?.type
        })
      }
      if (response?.type === 'team' && response?.project_id === id) {
        dispatch({
          type: 'GET_SENDED_MESSAGE',
          payload: modifiedResponse
        })
      } else if (response?.project_id === id) {
        dispatch({
          type: 'GET_CLIENT_SENDED_MESSAGE',
          payload: modifiedResponse
        })
      }
    }
    // Update the messages array with the received data
  }

  // Add a new 'privateMessages' event listener
  socket?.on('privateMessages', handlePrivateMessages)

  // Return a cleanup function to remove the 'privateMessages' event listener
  return () => {
    socket?.off('privateMessages', handlePrivateMessages)
  }
}

export const ListionUnreadMessage = dispatch => {
  // Remove any existing 'privateMessages' event listener before adding a new one
  socket?.off('newMessageArrived')

  const handlePrivateMessages = response => {
    let userInfo = store?.getState()?.login?.userDetails
    if (userInfo?._id !== response?.user_id) {
      dispatch({
        type: 'INCREASE_COUNT_PER_PROJECT',
        payload: response
      })
    }
  }
  // }
  // Add a new 'privateMessages' event listener
  socket?.on('newMessageArrived', handlePrivateMessages)

  // Return a cleanup function to remove the 'privateMessages' event listener
  return () => {
    socket?.off('newMessageArrived', handlePrivateMessages)
  }
}

export const ListionBothChatMessages = (dispatch, id, type) => {
  // Remove any existing 'privateMessages' event listener before adding a new one
  socket?.off('newMessageArrived')

  const handlePrivateMessages = response => {
    let userInfo = store?.getState()?.login?.userDetails
    if (
      response?.type !== type &&
      response?.projectId === id &&
      type === 'chat' &&
      userInfo?._id !== response.user_id
    ) {
      dispatch({
        type: 'INCREASE_COUNT',
        payload: response?.type
      })
    }
  }

  // Add a new 'privateMessages' event listener
  socket?.on('newMessageArrived', handlePrivateMessages)

  // Return a cleanup function to remove the 'privateMessages' event listener
  return () => {
    socket?.off('newMessageArrived', handlePrivateMessages)
  }
}

export const ErrorMessages = (id, type, userId) => {
  socket?.on('errorEvent', response => {
    console.log('errror', response)

    // Update the messages array with the received data
  })
  socket?.on('errorJoinRoomEvent', response => {
    console.log('errror JOIN RE-JOIN', response)

    joinRoom(id, type, userId)

    // Update the messages array with the received data
  })
}

export const UserDelete = setDeletedVal => {
  socket?.on('userDelete', response => {
    setDeletedVal(response)
  })
}

export const exitRoom = () => {
  socket?.emit('exitRoom')
}
